/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AChainbackLogo = ({ className }) => {
  return (
    <svg
      className={`a-chainback-logo ${className}`}
      fill="none"
      height="88"
      viewBox="0 0 230 88"
      width="230"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        className="ellipse"
        cx="28.9289"
        cy="44"
        fill="url(#paint0_linear_250_15366)"
        rx="18.9285"
        ry="18.9128"
      />
      <path
        className="path"
        d="M32.6711 42.7642L28.8053 39.8511L24.9395 42.0205V46.3592L28.8053 48.5286L32.6711 45.6154H39.5297V42.7642H32.6711Z"
        fill="white"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M16.5991 36.6614C23.7975 32.4898 32.6805 32.4898 39.8789 36.6614L38.4845 39.0634C32.3774 35.5242 24.8925 35.3963 18.6858 38.6798V49.728C24.8925 53.0115 32.3774 52.8837 38.4845 49.3445L39.8789 51.7465C32.6805 55.9181 23.7975 55.9181 16.5991 51.7465C16.1705 51.4981 15.9067 51.0405 15.9067 50.5455V37.8624C15.9067 37.3674 16.1705 36.9098 16.5991 36.6614Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M69.1631 39.8174C69.8926 39.8174 70.6221 39.9215 71.3516 40.1645C72.0812 40.4074 72.8107 40.7892 73.5402 41.3446L74.9297 38.1166C74.2697 37.5265 73.436 37.0753 72.3591 36.7282C71.3169 36.3811 70.2053 36.2075 69.0589 36.2075C67.2525 36.2075 65.6545 36.5893 64.3344 37.3182C63.0143 38.0472 61.9722 39.0885 61.2427 40.4074C60.5479 41.7611 60.1658 43.2884 60.1658 45.0586C60.1658 46.8288 60.5132 48.3907 61.2427 49.7444C61.9722 51.0634 63.0143 52.1047 64.3344 52.8683C65.6545 53.5973 67.2525 53.9791 69.0589 53.9791C70.2053 53.9791 71.2822 53.8055 72.3591 53.4584C73.436 53.1113 74.3044 52.6601 74.9297 52.07L73.5402 48.842C72.8107 49.3626 72.0812 49.7791 71.3516 50.0221C70.6221 50.2651 69.8926 50.4039 69.1631 50.4039C67.7736 50.4039 66.7314 49.9527 65.9671 49.0502C65.2376 48.1478 64.8555 46.8288 64.8555 45.0933C64.8555 43.3578 65.2376 42.0388 65.9671 41.1711C66.6967 40.2686 67.7736 39.8174 69.1631 39.8174Z"
        fill="white"
      />
      <path
        className="path"
        d="M89.9728 43.1507H82.9903V36.4517H78.509V53.7373H82.9903V46.7258H89.9728V53.7373H94.4541V36.4517H89.9728V43.1507Z"
        fill="white"
      />
      <path
        className="path"
        d="M104.769 36.4517L96.675 53.7373H101.261L102.789 50.1621H110.466L111.995 53.7373H116.476L108.382 36.4517H104.769ZM104.283 46.7258L106.645 41.2416L109.007 46.7258H104.283Z"
        fill="white"
      />
      <path className="path" d="M123.286 36.4517H118.7V53.7373H123.286V36.4517Z" fill="white" />
      <path
        className="path"
        d="M138.781 46.2052L130.93 36.4517H127.595V53.7373H131.868V43.9143L139.719 53.7373H143.089V36.4517H138.781V46.2052Z"
        fill="white"
      />
      <path
        className="path"
        d="M159.067 44.745C159.866 44.4673 160.526 44.016 160.978 43.3913C161.499 42.6624 161.777 41.864 161.777 40.9269C161.777 39.5385 161.221 38.4624 160.144 37.6294C159.067 36.8311 157.643 36.4146 155.837 36.4146H147.43V53.7349H156.045C157.956 53.7349 159.45 53.3183 160.561 52.4506C161.673 51.5828 162.229 50.4374 162.229 49.0143C162.229 47.9036 161.916 46.9664 161.291 46.2375C160.804 45.5433 160.04 45.0574 159.067 44.745ZM151.876 39.8856H155.072C155.906 39.8856 156.497 40.0244 156.879 40.3021C157.296 40.545 157.504 40.9963 157.504 41.5863C157.504 42.1417 157.296 42.5582 156.914 42.8359C156.531 43.1136 155.906 43.2177 155.072 43.2177H151.876V39.8856ZM157.4 49.882C157.018 50.1597 156.393 50.2986 155.524 50.2986H151.876V46.654H155.524C156.427 46.654 157.053 46.7929 157.435 47.1052C157.817 47.3829 157.991 47.8342 157.991 48.4936C157.991 49.1531 157.782 49.6044 157.4 49.882Z"
        fill="white"
      />
      <path
        className="path"
        d="M171.746 36.4517L163.651 53.7373H168.237L169.765 50.1621H177.443L178.971 53.7373H183.452L175.358 36.4517H171.746ZM171.259 46.7258L173.621 41.2416L175.984 46.7258H171.259Z"
        fill="white"
      />
      <path
        className="path"
        d="M193.7 39.8174C194.429 39.8174 195.159 39.9215 195.888 40.1645C196.618 40.4074 197.347 40.7892 198.077 41.3446L199.466 38.1166C198.806 37.5265 197.973 37.0753 196.896 36.7282C195.853 36.3811 194.742 36.2075 193.595 36.2075C191.789 36.2075 190.191 36.5893 188.871 37.3182C187.551 38.0472 186.509 39.0885 185.779 40.4074C185.084 41.7611 184.737 43.2884 184.737 45.0586C184.737 46.8288 185.084 48.3907 185.814 49.7444C186.543 51.0634 187.586 52.1047 188.906 52.8683C190.226 53.5973 191.824 53.9791 193.63 53.9791C194.777 53.9791 195.853 53.8055 196.93 53.4584C198.007 53.1113 198.876 52.6601 199.501 52.07L198.111 48.842C197.382 49.3626 196.652 49.7791 195.923 50.0221C195.193 50.2651 194.464 50.4039 193.734 50.4039C192.345 50.4039 191.303 49.9527 190.538 49.0502C189.809 48.1478 189.427 46.8288 189.427 45.0933C189.427 43.3578 189.809 42.0388 190.538 41.1711C191.268 40.2686 192.345 39.8174 193.7 39.8174Z"
        fill="white"
      />
      <path
        className="path"
        d="M212.08 44.6432L219.722 36.4517H214.372L207.633 43.8796V36.4517H203.082V53.7373H207.633V45.8928L214.581 53.7373H220L212.08 44.6432Z"
        fill="white"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_250_15366"
          x1="11.6524"
          x2="51.0236"
          y1="62.9128"
          y2="62.9128"
        >
          <stop className="stop" stopColor="#46FF7A" />
          <stop className="stop" offset="0.484375" stopColor="#52CBE7" />
          <stop className="stop" offset="1" stopColor="#7650DC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
