import React, {useContext, useState} from "react";
import "./style.scss";
import {PropertyDefaultWrapper} from "../PropertyDefaultWrapper";
import {BtnPrimaryMd} from "../BtnPrimaryMd";
import UserContext from "../UserContext";
import {displayDollars} from "../../api/utils";
import {createServer} from "../../api/auth";
import {Tooltip} from "react-tooltip";
import {useNavigate} from 'react-router-dom';
import requiredIcon from "../../img/icon-required-field.svg";

export const OrderSummarySection = ({os, gpu, plan, ssh}) => {
  const user = useContext(UserContext);
  const [isDeploying, setIsDeploying] = useState(false);
  const displayValue = (value, def = 'not set', isIcon = false) => value || <div className={'not-set-value'}>{isIcon && <img style={{height: '16px'}} src={requiredIcon}/>} {def}</div>;
  const canDeploy = os && gpu && plan && ssh && user && user.balance >= plan?.price.hourly;
  const navigate = useNavigate();
  const handleDeployClick = async () => {
    if (isDeploying) {
      return;
    }
    setIsDeploying(true);
    try {
      const result = await createServer({
        os_id: os.os_id,
        gpu: gpu,
        gpu_count: plan.configuration.gpu_count,
        ram: plan.configuration.ram,
        plan_id: plan.plan_id,
        ssh_key_id: ssh.keyId,
        region: plan.regions[0].id
      });
      navigate('/servers');
    } catch (e) {
      console.error(e);
      // todo: process error
    } finally {
      setIsDeploying(false);
    }
  }

  const deploymentFee = plan?.price?.hourly
  return (
      <>
        <div className="div-4">
          <div className="inputlabel">Order Summary</div>
        </div>
        <div className="div-5">
          <div className="order-summary">
            <div className="order-container">
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="OS"
                  text1={displayValue(os?.title, 'need to select OS', true)}
              />
              {plan?.configuration && <>
                <PropertyDefaultWrapper
                    className="design-component-instance-node-2"
                    text="RAM"
                    text1={displayValue(plan?.configuration.ram + 'GB', 'need to select server')}
                />
                <PropertyDefaultWrapper
                    className="design-component-instance-node-2"
                    text="Storage size"
                    text1={displayValue(plan?.configuration.storage_size + 'GB', 'need to select server')}
                />
                <PropertyDefaultWrapper
                    className="design-component-instance-node-2"
                    text="CPU cores"
                    text1={displayValue(plan?.configuration.core_count, 'need to select server')}
                />
              </>}
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="GPU"
                  text1={displayValue(gpu?.replace(/_/g, ' '), 'need to select GPU', true)}
              />
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="Deployment time"
                  text1={displayValue(plan?.deployment_time, 'will be available after setting all required fields')}
              />
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="Rate"
                  text1={displayValue(plan ? `${displayDollars(plan.price.hourly)} per hour` : null, 'will be available after setting all required fields')}
              />
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="Available balance"
                  text1={displayValue(user ? (!plan?.price.hourly
                      ? displayDollars(user?.balance)
                      : displayValue(user?.balance > plan?.price.hourly ? displayDollars(user?.balance) : null, displayDollars(user?.balance), true)) : null, 'not logged in')}
              />
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="SSH key"
                  text1={displayValue(ssh?.name, 'need to select or create a ssh key', true)}
              />
              <PropertyDefaultWrapper
                  className="design-component-instance-node-2"
                  text="Deployment fee"
                  text1={displayValue(deploymentFee ? displayDollars(deploymentFee) : null, 'will be available after setting all required fields')}
              />
            </div>
            {!canDeploy && <Tooltip style={{backgroundColor: '#F5576E'}}anchorSelect={".deploy-btn.disabled"}>Not all required parameters are configured</Tooltip> }
            <BtnPrimaryMd
                className="design-component-instance-node-5 deploy-btn"
                text="Deploy"
                type={canDeploy && !isDeploying ? 'default' : 'disabled'}
                onClick={handleDeployClick}
            />
          </div>
        </div>
      </>
  )
}
