/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Property1Variant2 } from "../../icons/Property1Variant2";
import "./style.css";

export const ResponsiveMenu = ({
  property1,
  line = "/img/line-2.svg",
  img = "/img/line-2.svg",
  line1 = "/img/line-3.svg",
}) => {
  return (
    <>
      {property1 === "default" && (
        <div className="responsive-menu">
          <img className="line" alt="Line" src={line} />
          <img className="img" alt="Line" src={img} />
          <img className="line-2" alt="Line" src={line1} />
        </div>
      )}

      {property1 === "variant-2" && <Property1Variant2 className="property" />}
    </>
  );
};

ResponsiveMenu.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  line: PropTypes.string,
  img: PropTypes.string,
  line1: PropTypes.string,
};
