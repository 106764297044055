/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ChainbackLogo = ({ className }) => {
  return (
    <svg
      className={`chainback-logo ${className}`}
      fill="none"
      height="50"
      viewBox="0 0 230 50"
      width="230"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse className="ellipse" cx="28.9289" cy="25" fill="url(#paint0_linear_303_3367)" rx="18.9285" ry="18.9128" />
      <path
        className="path"
        d="M32.6711 23.7642L28.8053 20.8511L24.9395 23.0205V27.3592L28.8053 29.5286L32.6711 26.6154H39.5297V23.7642H32.6711Z"
        fill="white"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M16.5991 17.6614C23.7975 13.4898 32.6805 13.4898 39.8789 17.6614L38.4845 20.0634C32.3774 16.5242 24.8925 16.3963 18.6858 19.6798V30.728C24.8925 34.0115 32.3774 33.8837 38.4845 30.3445L39.8789 32.7465C32.6805 36.9181 23.7975 36.9181 16.5991 32.7465C16.1705 32.4981 15.9067 32.0405 15.9067 31.5455V18.8624C15.9067 18.3674 16.1705 17.9098 16.5991 17.6614Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M69.1629 20.8174C69.8924 20.8174 70.6219 20.9215 71.3514 21.1645C72.0809 21.4074 72.8104 21.7892 73.5399 22.3446L74.9295 19.1166C74.2695 18.5265 73.4357 18.0753 72.3588 17.7282C71.3167 17.3811 70.205 17.2075 69.0586 17.2075C67.2522 17.2075 65.6543 17.5893 64.3342 18.3182C63.0141 19.0472 61.9719 20.0885 61.2424 21.4074C60.5477 22.7611 60.1655 24.2884 60.1655 26.0586C60.1655 27.8288 60.5129 29.3907 61.2424 30.7444C61.9719 32.0634 63.0141 33.1047 64.3342 33.8683C65.6543 34.5973 67.2522 34.9791 69.0586 34.9791C70.205 34.9791 71.2819 34.8055 72.3588 34.4584C73.4357 34.1113 74.3042 33.6601 74.9295 33.07L73.5399 29.842C72.8104 30.3626 72.0809 30.7791 71.3514 31.0221C70.6219 31.2651 69.8924 31.4039 69.1629 31.4039C67.7733 31.4039 66.7312 30.9527 65.9669 30.0502C65.2374 29.1478 64.8553 27.8288 64.8553 26.0933C64.8553 24.3578 65.2374 23.0388 65.9669 22.1711C66.6964 21.2686 67.7733 20.8174 69.1629 20.8174Z"
        fill="white"
      />
      <path
        className="path"
        d="M89.9726 24.1507H82.9901V17.4517H78.5088V34.7373H82.9901V27.7258H89.9726V34.7373H94.4539V17.4517H89.9726V24.1507Z"
        fill="white"
      />
      <path
        className="path"
        d="M104.769 17.4517L96.6748 34.7373H101.26L102.789 31.1621H110.466L111.995 34.7373H116.476L108.382 17.4517H104.769ZM104.283 27.7258L106.645 22.2416L109.007 27.7258H104.283Z"
        fill="white"
      />
      <path className="path" d="M123.286 17.4517H118.7V34.7373H123.286V17.4517Z" fill="white" />
      <path
        className="path"
        d="M138.781 27.2052L130.93 17.4517H127.595V34.7373H131.868V24.9143L139.719 34.7373H143.088V17.4517H138.781V27.2052Z"
        fill="white"
      />
      <path
        className="path"
        d="M159.067 25.745C159.866 25.4673 160.526 25.016 160.978 24.3913C161.499 23.6624 161.777 22.864 161.777 21.9269C161.777 20.5385 161.221 19.4624 160.144 18.6294C159.067 17.8311 157.643 17.4146 155.836 17.4146H147.43V34.7349H156.045C157.956 34.7349 159.449 34.3183 160.561 33.4506C161.673 32.5828 162.228 31.4374 162.228 30.0143C162.228 28.9036 161.916 27.9664 161.29 27.2375C160.804 26.5433 160.04 26.0574 159.067 25.745ZM151.876 20.8856H155.072C155.906 20.8856 156.496 21.0244 156.879 21.3021C157.295 21.545 157.504 21.9963 157.504 22.5863C157.504 23.1417 157.295 23.5582 156.913 23.8359C156.531 24.1136 155.906 24.2177 155.072 24.2177H151.876V20.8856ZM157.4 30.882C157.018 31.1597 156.392 31.2986 155.524 31.2986H151.876V27.654H155.524C156.427 27.654 157.052 27.7929 157.434 28.1052C157.817 28.3829 157.99 28.8342 157.99 29.4936C157.99 30.1531 157.782 30.6044 157.4 30.882Z"
        fill="white"
      />
      <path
        className="path"
        d="M171.746 17.4517L163.651 34.7373H168.237L169.765 31.1621H177.443L178.971 34.7373H183.452L175.358 17.4517H171.746ZM171.259 27.7258L173.621 22.2416L175.984 27.7258H171.259Z"
        fill="white"
      />
      <path
        className="path"
        d="M193.699 20.8174C194.429 20.8174 195.158 20.9215 195.888 21.1645C196.617 21.4074 197.347 21.7892 198.076 22.3446L199.466 19.1166C198.806 18.5265 197.972 18.0753 196.895 17.7282C195.853 17.3811 194.742 17.2075 193.595 17.2075C191.789 17.2075 190.191 17.5893 188.871 18.3182C187.551 19.0472 186.508 20.0885 185.779 21.4074C185.084 22.7611 184.737 24.2884 184.737 26.0586C184.737 27.8288 185.084 29.3907 185.814 30.7444C186.543 32.0634 187.585 33.1047 188.905 33.8683C190.226 34.5973 191.824 34.9791 193.63 34.9791C194.776 34.9791 195.853 34.8055 196.93 34.4584C198.007 34.1113 198.875 33.6601 199.501 33.07L198.111 29.842C197.382 30.3626 196.652 30.7791 195.923 31.0221C195.193 31.2651 194.464 31.4039 193.734 31.4039C192.345 31.4039 191.302 30.9527 190.538 30.0502C189.809 29.1478 189.427 27.8288 189.427 26.0933C189.427 24.3578 189.809 23.0388 190.538 22.1711C191.268 21.2686 192.345 20.8174 193.699 20.8174Z"
        fill="white"
      />
      <path
        className="path"
        d="M212.079 25.6432L219.722 17.4517H214.372L207.633 24.8796V17.4517H203.082V34.7373H207.633V26.8928L214.581 34.7373H220L212.079 25.6432Z"
        fill="white"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_303_3367"
          x1="11.6524"
          x2="51.0236"
          y1="43.9128"
          y2="43.9128"
        >
          <stop className="stop" stopColor="#46FF7A" />
          <stop className="stop" offset="0.484375" stopColor="#52CBE7" />
          <stop className="stop" offset="1" stopColor="#7650DC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
