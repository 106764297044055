import React, {useContext, useEffect, useState} from "react";
import UserContext from "../UserContext";
import {BtnPrimaryMd} from "../BtnPrimaryMd";
import {displayDollars, isLoggedInUser, shortenWalletAddress} from "../../api/utils";
import "./style.scss";
import {topUp} from "../../utils/web3-connector";
import {ChildrenType, Tooltip} from "react-tooltip";
import ReactModal from 'react-modal';
import {ModalCloseIcon} from "../../icons/ModalCloseIcon";
import chainbackLogoIcon from "../../img/chainback-logo-icon.png";
import {getApplicationState} from "../../api/public";

const CURRENCY_USD = 'usd';
const CURRENCY_ARCHIVE = 'archive';

export const UserHeaderPanel = ({onLoginClick, onLogoutClick}) => {
  const [isUserBlock, setIsUserBlock] = useState(false);
  const [isTopupModal, setIsTopupModal] = useState(false);
  const [archivePriceUsd, setArchivePriceUsd] = useState(1);
  const getArchiveBalance = (usdValue) => Math.floor(usdValue / archivePriceUsd);
  const [usdValue, setUsdValue] = useState(10);
  const [archiveValue, setArchiveValue] = useState(getArchiveBalance(10));
  const [messageAfterTopUp, setMessageAfterTopUp] = useState('');
  const user = useContext(UserContext);

  useEffect(() => {
    setArchiveValue(getArchiveBalance(usdValue));
  }, [archivePriceUsd]);

  const handleModalInputChange = (currency, newValue) => {
    if (newValue === '' || Number(newValue) < 0) {
      if (currency === CURRENCY_USD) {
        setUsdValue(-1);
        setArchiveValue(0);
      } else if (currency === CURRENCY_ARCHIVE) {
        setUsdValue(0);
        setArchiveValue(-1);
      }
      return;
    }
    newValue = Number(newValue);
    if (isNaN(newValue)) {
      return;
    }
    if (currency === CURRENCY_USD) {
      setUsdValue(newValue);
      setArchiveValue(getArchiveBalance(newValue));
    } else if (currency === CURRENCY_ARCHIVE) {
      setArchiveValue(newValue);
      setUsdValue(Math.round(newValue * archivePriceUsd * 100) / 100 || 0);
    }
  }

  if (isLoggedInUser(user)) {
    let address = user.walletId;
    const handleTopUpClick = () => {
      getApplicationState().then(data => {
        setArchivePriceUsd(data.archivePriceUsd);
        setIsTopupModal(true);
      });
    }

    const handleTopUp = async () => {
      setMessageAfterTopUp('');
      const result = await topUp(user.walletId, archiveValue);
      if (result === null) {
        // ignore
      } else {
        setMessageAfterTopUp('Your balance will be topped up automatically after all confirmations');
      }
    }
    const addressShort = shortenWalletAddress(address, 15);
    address = shortenWalletAddress(address, 15);
    const balance = user.balance;
    return (
        <div className="user-header-panel">
          <ReactModal isOpen={isTopupModal} className={'top-up-modal'} overlayClassName={'top-up-modal-overlay'} ariaHideApp={false}>
            <Tooltip
                style={{backgroundColor: '#712AF9', borderRadius: '6px'}}
                anchorSelect=".btn-buy-archive" openOnClick={false}>Coming soon</Tooltip>
            <ModalCloseIcon className="top-up-modal-close-btn" onClick={() => setIsTopupModal(false)}/>
            <div className="top-up-modal-body">
              <h2>Top Up Now</h2>
              <div className="top-up-balance-container">
                <img className="top-up-chainback-logo-big" src={chainbackLogoIcon} alt={'Chainback logo icon'}/>
                <div className="top-up-balance-content">
                  <h3>Your balance</h3>
                  <div className="top-up-balance-value">{displayDollars(balance)}</div>
                  <div>
                    <img className="top-up-chainback-logo-small" src={chainbackLogoIcon}
                         alt={'Chainback logo icon small'}/> <span className="top-up-balance-archive-value">{Math.round(balance / archivePriceUsd)} $ARCHIVE</span>
                  </div>
                </div>
              </div>
              <div className="top-up-form-container">
                <label className="top-up-form-field">
                  <span>I want to spend USD</span>
                  <div className={"top-up-form-input-container"}>
                    <input className={"top-up-form-input"} type="number" value={usdValue < 0 ? '' : usdValue} onChange={e => handleModalInputChange(CURRENCY_USD, e.target.value)}/>
                    <span className={"top-up-form-input-currency"}>$</span>
                  </div>
                </label>
                <label className="top-up-form-field">
                  <span>I want to spend ARCHIVE</span>
                  <div className={"top-up-form-input-container"}>
                    <input className={"top-up-form-input"} type="number" value={archiveValue < 0 ? '' : archiveValue} onChange={e => handleModalInputChange(CURRENCY_ARCHIVE, e.target.value)}/>
                    <span className={"top-up-form-input-currency"}>ARCHIVE</span>
                  </div>
                </label>
              </div>
              <div className="top-up-amount-choice">
                {[10, 20, 50, 100, 200].map(amount => (
                    <div key={amount} onClick={() => handleModalInputChange(CURRENCY_USD, amount)} className={'top-up-amount-choice-button-container' + (amount === usdValue ? ' selected' : '')}>
                      <div className="top-up-amount-choice-button-body">${amount}</div>
                    </div>
                ))}
              </div>
              {messageAfterTopUp && <div className={"message-after-top-up"}>{messageAfterTopUp}</div>}
              <div className="top-up-buttons-container">
                <button className="top-up-form-btn top-up-form-btn-container btn-buy-archive coming-soon disabled">
                  <div className="top-up-form-btn-body">Buy Archive</div>
                </button>
                <button className="top-up-form-btn top-up-form-btn-container btn-top-up-modal" onClick={handleTopUp}>
                  <div className="top-up-form-btn-body">Top Up</div>
                </button>
              </div>
            </div>
          </ReactModal>
          <div className="address-button-container" onClick={() => setIsUserBlock(!isUserBlock)}>
            <div className="address-button">
              {addressShort}
            </div>
          </div>
          {isUserBlock && <div className="user-info-block">
            {/*<Tooltip*/}
            {/*    style={{backgroundColor: '#712AF9', borderRadius: '6px'}}*/}
            {/*    anchorSelect=".coming-soon" openOnClick={false}>Coming soon</Tooltip>*/}
            <div className="user-info-block-body">
              <h4>Your balance</h4>
              <div className="user-balance">
                <span>{displayDollars(balance)}</span>
                <div className="btn-top-up-container">
                  <button className="btn btn-top-up" onClick={handleTopUpClick}>
                    <div className="btn-top-up-body">
                      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_504_5730" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="1" y="0"
                              width="17" height="18">
                          <path
                              d="M9.5 16.5C13.6423 16.5 17 13.1423 17 9C17 4.85775 13.6423 1.5 9.5 1.5C5.35775 1.5 2 4.85775 2 9C2 13.1423 5.35775 16.5 9.5 16.5Z"
                              fill="#555555" stroke="white" strokeWidth="1.5" strokeLinejoin="round"/>
                          <path d="M9.5 6V12M6.5 9H12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </mask>
                        <g mask="url(#mask0_504_5730)">
                          <path d="M0.5 0H18.5V18H0.5V0Z" fill="url(#paint0_linear_504_5730)"/>
                        </g>
                        <defs>
                          <linearGradient id="paint0_linear_504_5730" x1="1.28545" y1="18" x2="20.0055" y2="18"
                                          gradientUnits="userSpaceOnUse">
                            <stop stopColor="#46FF7A"/>
                            <stop offset="0.484375" stopColor="#52CBE7"/>
                            <stop offset="1" stopColor="#7650DC"/>
                          </linearGradient>
                        </defs>
                      </svg>
                      <span>Top Up</span>
                    </div>
                  </button>
                </div>
              </div>
              <h4>Wallet</h4>
              <span className="user-address">
                {address}
              </span>


              <BtnPrimaryMd className="btn-primary-md-instance user-logout-button" text="Logout" type="default"
                            onClick={onLogoutClick}/>
            </div>
          </div>}
        </div>
    )
  }
  return (
      <BtnPrimaryMd className="btn-primary-md-instance" text="Connect Wallet" type="default" onClick={onLoginClick}/>
  )
}
