import {OsCard} from "../OsCard";
import PropTypes from "prop-types";

const OsSection = ({
                     onSelectOs = (os) => {
                     }, options = [], selectedOs = null
                   }) => {
  const selectedId = selectedOs ? selectedOs.os_id : null;

  return (
      <div className="os-section">
        <div className="os-caption">
          <div className="input-label-9">OS Template</div>
        </div>
        <div className="os-options">
          {options.map(o => {
            const parts = o.title.split('-');
            let description = '';
            if (o.os_id === 'SD2.0') {
              description = <p>Custom image with AI/ML and Stable Diffusion software preloaded, based on Ubuntu 20.<br/>Preinstalled packages include: Cuda, Conda, TensorFlow, PyTorch, Keras, Jupyter: Notebooks & Lab, Python3 , Numpy, OpenCV, cuDNN, NCCL, Docker, NVIDIA-Docker.</p>
            } else if (o.os_id === 'FluidML20') {
              description = <p>Custom image with AI/ML software preloaded, based on Ubuntu 20. <br/>Preinstalled packages include: Cuda, Conda, TensorFlow, PyTorch, Keras, Jupyter: Notebooks & Lab, Python3 , Numpy, OpenCV, cuDNN, NCCL, Docker, NVIDIA-Docker.</p>
            } else if (o.os_id === 'Ubuntu 20.04 LTS (Nvidia)') {
              description = <p>Ubuntu image with NVIDIA drivers pre-installed.</p>
            }
            return <OsCard
                key={o.os_id}
                id={o.os_id}
                className="design-component-instance-node-4"
                title={parts[0].trim()}
                subtitle={parts[1].trim().replace(/[\(\)]/g, '')}
                version={""}
                type={selectedId === o.os_id ? 'active' : 'default'}
                onClick={() => onSelectOs(o)}
                description={description}
            />
          })}
        </div>
      </div>
  )
}

OsSection.propTypes = {
  onSelectOs: PropTypes.func,
  options: PropTypes.array,
  selectedOs: PropTypes.object
}

export default OsSection;
