import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { completeLogin, logout, startLogin } from './auth';
import { disconnectWeb3 } from '../api/axios';
import { localStoragePrefix } from "./auth";

const blockchainId = BigInt(parseInt(process.env.REACT_APP_BLOCKCHAIN_ID, 10));
const blockchainRpc = process.env.REACT_APP_RPC;

export const connectWeb3 = async () => {
  // eslint-disable-next-line no-use-before-define
  initWeb3Modal();
  return (new Web3(await window.web3Modal.connect()));
};


const switchNetwork = async (web3) => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(blockchainId) }],
    });
  } catch (err) {
    // This error code indicates that the chain has not been added to MetaMask
    if (err.code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: 'Chainback Ethereum',
            chainId: web3.utils.toHex(blockchainId),
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: [blockchainRpc],
          },
        ],
      });
    }
  }
};

// eslint-disable-next-line no-async-promise-executor
export const handleLogin = () => (new Promise(async (resolve, reject) => {
  let web3;
  try {
    web3 = await connectWeb3();
  } catch (error) {
    if (error !== 'Modal closed by user' && error?.message !== 'User closed modal') {
      console.error('Allow access to wallet account');
    }
    reject();
    return;
  }

  const chainId = await web3.eth.getChainId();
  if (chainId !== blockchainId) {
    await switchNetwork(web3);
    handleLogin().then(resolve);
    return;
  }

  const walletId = await web3.eth.getCoinbase();
  if (!walletId) {
    disconnectWeb3();
    console.error('Turn on Wallet Account');
    reject();
    return;
  }

  startLogin(walletId).then(
    (loginResponse) => {
      web3.currentProvider.request({
        method: 'personal_sign',
        params: [loginResponse.messageToSign, loginResponse.walletId, ''],
      }).then(
        (signature) => completeLogin(walletId, signature).then(() => resolve(walletId)),
      ).catch((err) => {
        disconnectWeb3();
        console.error('Sign message to login', err);
        reject();
      });
    },
  );
}));

const initWeb3Modal = () => {
  if (((typeof window) !== 'undefined') && !window.web3Modal) {
    window.web3Modal = new Web3Modal({
      theme: 'dark',
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [blockchainId]: blockchainRpc,
            },
          },
        },
      },
    });
    window.addEventListener('load', () => {
      if (window.ethereum) {
        window.ethereum.enable();
        window.ethereum.on('accountsChanged', (walletIds) => {
          if (localStorage.getItem(localStoragePrefix + 'user')) {
            console.log('Account changed', walletIds);
            logout().then(handleLogin);
          }
        });
        window.ethereum.on('networkChanged', (newtworkId) => {
          if (localStorage.getItem(localStoragePrefix + 'user') && (newtworkId !== blockchainId)) {
            console.log('Network changed', newtworkId);
            logout();
          }
        });
      }
    });
  }
};

const ARCHIVE_CONTRACT = process.env.REACT_APP_TOKEN_ARCHIVE_CONTRACT;
const MASTER_WALLET_ID = process.env.REACT_APP_MASTER_WALLET_ID;

export const topUp = async (walletId, amount = 5) => {
  const web3 = await connectWeb3();
  const archiveContract = await connectArchiveContract(web3);
  try {
    await archiveContract.methods.transfer(MASTER_WALLET_ID, amount * 10 ** 18).send({from: walletId});
  } catch (e) {
    console.log('catch');
    return null;
  }
  return web3;
}

export const connectArchiveContract = (web3) => (new web3.eth.Contract(
    [
      {
        inputs: [],
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256',
          },
        ],
        name: 'Approval',
        type: 'event',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'approve',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'subtractedValue',
            type: 'uint256',
          },
        ],
        name: 'decreaseAllowance',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'addedValue',
            type: 'uint256',
          },
        ],
        name: 'increaseAllowance',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_amount',
            type: 'uint256',
          },
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_amount',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        name: 'mintTo',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'transfer',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256',
          },
        ],
        name: 'Transfer',
        type: 'event',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'transferFrom',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
        ],
        name: 'allowance',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'account',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'decimals',
        outputs: [
          {
            internalType: 'uint8',
            name: '',
            type: 'uint8',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'name',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    ARCHIVE_CONTRACT,
));

export default { connectWeb3 };
