import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {defaultHandler} from "../../api/utils";

export const RadioButton = ({ type, className, onClick = defaultHandler }) => {
  return (
    <div
      className={`radio-button state-${type} ${className}`}
      onClick={onClick}
    >
      <div className="controller">
        <div className="thumb">{type === "active" && <div className="small-circle" />}</div>
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  type: PropTypes.oneOf(["active", "default"]),
};
