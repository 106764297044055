import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from "./pages/main/MainPage";
import UserProvider from "./components/UserProvider";
import {ServersPage} from "./pages/servers/ServersPage";
import {ChainbackLogo} from "./icons/ChainbackLogo";
import {Sidebar} from "./components/Sidebar";
import {Header} from "./components/Header";
import {handleLogin} from "./utils/web3-connector";
import {processLogout} from "./utils/auth";
import {Footer} from "./components/Footer";
import {Tooltip} from "react-tooltip";
import {useContext} from "react";
import UserContext from "./components/UserContext";

const getPage = (Page) => {
  return (
      <UserProvider>
        <div className="application">
          <div className="virtual-machines">
            <div className="page-container">
              <div className="left-column">
                <ChainbackLogo className="chainback-logo"/>
                <Sidebar className="design-component-instance-node-2"/>
              </div>
              <div className="content-container">
                <Header
                    btnPrimaryMdText="Connect Wallet"
                    className="design-component-instance-node-3"
                    type="default"
                    isMobileMenu={false}
                    onLoginClick={handleLogin}
                    onLogoutClick={processLogout}
                />
                <div className="content-2">
                  <Page/>
                </div>
                <Footer className="design-component-instance-node-3" href="mailto:partners@chainback.org"
                        screen="desktop"/>
              </div>
              <Tooltip
                  style={{backgroundColor: '#712AF9', borderRadius: '6px'}}
                  anchorSelect=".coming-soon" openOnClick={false}>Coming soon</Tooltip>
            </div>
          </div>
        </div>
      </UserProvider>
  )
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={getPage(MainPage)}/>
        <Route path="/servers" element={getPage(ServersPage)}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
