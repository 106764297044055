import {
  requestLoginComplete,
  requestLoginStart,
  requestLogout,
} from '../api/auth';
import { disconnectWeb3 } from '../api/axios';


// let navigate;

// export const initAuthUtils = (navigateFunc) => {
export const initAuthUtils = () => {
  // navigate = navigateFunc;
};

export const localStoragePrefix = 'gpu_';

const loginCallback = (session) => {
  localStorage.setItem(localStoragePrefix + 'session', JSON.stringify(session));
  localStorage.setItem(localStoragePrefix + 'user', JSON.stringify({ id: session.userId, walletId: session.walletId }));
  localStorage.setItem(localStoragePrefix + 'sessionToken', session.token);
  localStorage.setItem(localStoragePrefix + 'sessionTokenExpirationDate', JSON.stringify(session.expirationDate));
  window.dispatchEvent(new Event('storage'));
};

export const startLogin = (walletId) => requestLoginStart(walletId);

export const completeLogin = (walletId, signature) => requestLoginComplete(walletId, signature).then(loginCallback);

export const processLogout = () => {
  localStorage.removeItem(localStoragePrefix + 'session');
  localStorage.removeItem(localStoragePrefix + 'user');
  localStorage.removeItem(localStoragePrefix + 'sessionToken');
  localStorage.removeItem(localStoragePrefix + 'sessionTokenExpirationDate');
  window.dispatchEvent(new Event('storage'));
  disconnectWeb3();
};


export const logout = () => requestLogout().then(processLogout);

export default { initAuthUtils, startLogin, completeLogin, logout };
