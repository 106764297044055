import {LaunchpadTabPanel} from "../LaunchpadTabPanel";
import {Tooltip} from "react-tooltip";

export const SERVER_CUSTOMIZATION_BASIC = 'basic';
export const SERVER_CUSTOMIZATION_ADVANCED = 'advanced';

export const ServerCustomizationChoice = ({onSelect, selected = SERVER_CUSTOMIZATION_BASIC, className = '', disabled = false}) => {
  if (className) {
    className = ' ' + className;
  }
  return (<>
    <div className="div-4">
      <div className="inputlabel">Server Customization</div>
      <p className="p-inputlabel">
        Select advanced if you want to configure disk space and to select data center location if available.
      </p>
    </div>
    <div className={"server-types-container" + className}>
      <div className="server-types">
        <LaunchpadTabPanel className={"design-component-instance-node-4" + (disabled ? ' blocked' : '')}
                           text="Basic"
                           type={selected === SERVER_CUSTOMIZATION_BASIC ? 'active' : 'default'}
                           onClick={() => onSelect(SERVER_CUSTOMIZATION_BASIC)}
        />
        <LaunchpadTabPanel className={"design-component-instance-node-4" + (disabled ? ' blocked' : '')}
                           text="Advanced"
                           type={selected === SERVER_CUSTOMIZATION_ADVANCED ? 'active' : 'default'}
                           onClick={() => onSelect(SERVER_CUSTOMIZATION_ADVANCED)}
        />
      </div>
    </div>
  </>)
}
