/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PropertyDefaultWrapper = ({ className, text = "Additional disk", text1 = "0 GB" }) => {
  return (
    <div className={`property-default-wrapper ${className}`}>
      <div className="column-5">
        <div className="additional-disk-wrapper">
          <div className="additional-disk">{text}</div>
        </div>
        <div className="element-GB-wrapper">
          <div className="element-GB">{text1}</div>
        </div>
      </div>
    </div>
  );
};

PropertyDefaultWrapper.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.any,
};
