import {useContext, useEffect, useState} from "react";
import {doServerAction, getUserServers} from "../../api/auth";
import {defaultHandler, displayDollars, isLoggedInUser} from "../../api/utils";
import UserContext from "../../components/UserContext";
import "./style.scss";
import { confirmAlert } from 'react-confirm-alert'; // Import

export const ServersPage = () => {
  const [userServers, setUserServers] = useState([]);
  const user = useContext(UserContext);
  const loggedIn = () => isLoggedInUser(user);

  useEffect(() => {
    if (!isLoggedInUser(user)) {
      return;
    }
    getUserServers().then(data => {
      setUserServers(data);
    });
  }, [user]);

  if (!isLoggedInUser(user)) {
    return (<div className="text-center">Please login to get your servers list</div>);
  }

  const handleServerAction = (server, action) => async () => {
    if (action === 'terminate') {
      confirmAlert({
        title: 'Server termination confirmation',
        message: 'Are you sure you want to terminate server?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              await doServerAction(server.id, action);
              const servers = await getUserServers();
              setUserServers(servers);
            }
          },
          {
            label: 'No',
            onClick: defaultHandler
          }
        ]
      })
    } else {
      if (action === 'stop' && !isRunning(server)) {
        return;
      }
      if (action === 'start' && !isStopped(server)) {
        return;
      }
      await doServerAction(server.id, action);
      const servers = await getUserServers();
      setUserServers(servers);
    }
  }

  const isRunning = (server) => server.status === 'running';
  const isStopped = server => server.status === 'stopped';
  const isTerminating = server => server.status === 'terminating';

  return (
      <div className="server-selection-wrapper">
        <table className="plans-table servers-table">
          <thead>
          <tr>
            <th><span>OS</span></th>
            <th><span>CPU</span></th>
            <th><span>Address</span></th>
            <th><span>SSH</span></th>
            <th><span>GPU</span></th>
            <th><span>RAM</span></th>
            <th><span>Disk space</span></th>
            <th><span>Status</span></th>
            <th><span>Rates</span></th>
            <th><span>Current rate</span></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {userServers && userServers.map(s => (<tr key={s.id}>
            <td><span>{s.os}</span></td>
            <td><span>{s.config.cpu_model}<br/>{s.config.cpu_count} cores</span></td>
            <td><span>{s.ip_address ? <>{s.ip_address}, port {s.ssh_port}</> : 'not ready yet'}</span></td>
            <td><span>Key name: {s.ssh_key}<br/>Login: fsuser</span></td>
            <td><span>{s.config.gpu_model}</span></td>
            <td><span>{s.config.ram}GB</span></td>
            <td><span>{s.config.nvme_storage}GB</span></td>
            <td><span>{s.status === 'stop' ? 'stopping' : s.status === 'start' ? 'starting' : s.status}</span></td>
            <td><span>Running: {displayDollars(s.running_rate)}/h<br/>Stopped: {displayDollars(s.stopped_rate)}/h</span>
            </td>
            <td><span className="text-bold">{displayDollars(s.current_rate)}/h</span></td>
            <td>
              {isRunning(s) && <button className={"btn btn-stop"} onClick={handleServerAction(s, 'stop')}>Stop</button>}
              {isStopped(s) && <button className={"btn btn-start"} onClick={handleServerAction(s, 'start')}>Start</button>}
              {!isTerminating(s) && <button className={"btn btn-terminate"} onClick={handleServerAction(s, 'terminate')}>Terminate</button>}
              </td>
          </tr>))}
          </tbody>
        </table>
      </div>
  )
}
