import "./style.scss";
import {RadioButton} from "../RadioButton";
import {defaultHandler, displayDollars} from "../../api/utils";

export const PlansSection = ({options = [], onPlanSelect = defaultHandler, selectedPlanId = null}) => {
  const handlePlanClick = plan => {
    onPlanSelect(plan);
  }
  return (
      <div className="tarrifs-section">
        <table className="plans-table">
          <thead>
            <tr>
              <th><span>CPU</span></th>
              <th><span>CPU<br/>cores</span></th>
              <th><span>GPUs</span></th>
              <th><span>RAM<br/>(Gb)</span></th>
              <th><span>Local<br/>Storage<br/>(Gb)</span></th>
              <th><span>Location</span></th>
              <th><span>Deployment<br/>Time</span></th>
              <th><span>Price<br/>per<br/>hour</span></th>
              <th>

              </th>
            </tr>
          </thead>
          <tbody>
          {options.map(data => <tr key={data.plan_id} onClick={() => handlePlanClick(data)}>
            <td><span>{data.cpu_type}</span></td>
            <td><span>{data.configuration.core_count}</span></td>
            <td><span>{data.configuration.gpu_count}</span></td>
            <td><span>{data.configuration.ram}</span></td>
            <td><span>{data.configuration.storage_size}</span></td>
            <td><span>{data.regions.map(r => r.description).join(', ')}</span></td>
            <td><span>{data.deployment_time}</span></td>
            <td><span>est. {displayDollars(data.price.hourly)}</span></td>
            <td>
              <span>
                <RadioButton type={selectedPlanId === data.plan_id ? 'active' : 'default'}/>
              </span>
            </td>
          </tr>)}
          </tbody>
        </table>
      </div>
  )
}
