import React from 'react';
import {defaultHandler} from "../../api/utils";

export const ModalCloseIcon = ({className = '', onClick = defaultHandler}) => {
  return (<svg className={className} onClick={onClick} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_512_5876)">
          <mask id="mask0_512_5876" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="3" y="3" width="34" height="34">
            <path d="M20 36.6668C29.205 36.6668 36.6667 29.2052 36.6667 20.0002C36.6667 10.7952 29.205 3.3335 20 3.3335C10.795 3.3335 3.33337 10.7952 3.33337 20.0002C3.33337 29.2052 10.795 36.6668 20 36.6668Z" fill="#555555"/>
            <path d="M24.7142 15.2861L15.2859 24.7145M15.2859 15.2861L24.7142 24.7145" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
          </mask>
          <g mask="url(#mask0_512_5876)">
            <path d="M0 0H40V40H0V0Z" fill="url(#paint0_linear_512_5876)"/>
          </g>
        </g>
        <defs>
          <linearGradient id="paint0_linear_512_5876" x1="1.74545" y1="40" x2="43.3455" y2="40" gradientUnits="userSpaceOnUse">
            <stop stopColor="#46FF7A"/>
            <stop offset="0.484375" stopColor="#52CBE7"/>
            <stop offset="1" stopColor="#7650DC"/>
          </linearGradient>
          <clipPath id="clip0_512_5876">
            <rect width="40" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>
  );
}
