import axios from 'axios';
import {localStoragePrefix} from "../utils/auth";

export const disconnectWeb3 = () => {
  localStorage.removeItem('walletconnect');
  if (window.web3Modal) {
    window.web3Modal.clearCachedProvider();
  }
};

const displayToast = (status, data) => {
  switch (status) {
    case 429:
      console.warn('Too many requests. Please try again a moment later');
      return;
    default:
      console.error(data?.message || 'Something went wrong');
  }
};


// let navigate;
// export const initAxiosUtils = (navigateFunc) => {
export const initAxiosUtils = () => {
  // navigate = navigateFunc;
};

const processLogout = () => {
  localStorage.removeItem(localStoragePrefix + 'session');
  localStorage.removeItem(localStoragePrefix + 'user');
  localStorage.removeItem(localStoragePrefix + 'sessionToken');
  localStorage.removeItem(localStoragePrefix + 'sessionTokenExpirationDate');
  window.dispatchEvent(new Event('storage'));
  disconnectWeb3();
  location.reload();
};


axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.withCredentials = true;
  if (!config?.headers?.sessiontoken) {
    const authorizationHeader = localStorage.getItem(localStoragePrefix + 'sessionToken');
    if (authorizationHeader) {
      if (!config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers = {};
      }
      // eslint-disable-next-line no-param-reassign
      config.headers.sessiontoken = authorizationHeader;
    }
  }
  return config;
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const {response: {status, data}} = error;

      if (
          (
              (error.status === 500 && error.data.message === 'TokenExpiredError') ||
              (error.status === 401) ||
              (error.response?.status === 401)
          ) &&
          !error.response.config.url.endsWith('api/refreshToken') &&
          !error.response.config.url.endsWith('api/login')
      ) {
        console.log('process logout');
        processLogout();
        // waiting for logout
        // eslint-disable-next-line no-promise-executor-return
        return Promise.reject(error);
      }

      displayToast(status, data);

      return Promise.reject(error);
    },
);

export default axios;
