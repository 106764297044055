import axios from './axios';
import {BACKEND_URL} from "./utils";

export const requestLoginStart = (walletId) => axios.post(
    `${BACKEND_URL}/api/login/start`,
    { walletId },
).then(({ data }) => data);

export const requestLoginComplete = (walletId, signature) => axios.post(
    `${BACKEND_URL}/api/login/complete`,
    { walletId, signature },
).then(({ data }) => data);

export const requestLogout = () => axios.post(`${BACKEND_URL}/api/logout`);

export const checkAuth = () => axios.post(`${BACKEND_URL}/api/auth/check`);

export default { requestLoginStart, requestLoginComplete, requestLogout, checkAuth };

export const getUserInfo = () => axios.get(`${BACKEND_URL}/api/user/info`).then(({data}) => data);

export const getSshKeys = () => axios.get(`${BACKEND_URL}/api/user/ssh`).then(({data}) => data);

export const createSshKey = (name, publicKey) => axios.post(`${BACKEND_URL}/api/user/ssh/create`, {name, public_key: publicKey}).then(({data}) => data);

export const createServer = (data) => axios.post(`${BACKEND_URL}/api/user/servers/create`, data).then(({data}) => data);

export const getUserServers = () => axios.get(`${BACKEND_URL}/api/user/servers`).then(({data}) => data);

export const doServerAction = (serverId, action) => axios.post(`${BACKEND_URL}/api/user/server/${serverId}/${action}`).then(({data}) => data);
