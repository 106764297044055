/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.scss";

export const Footer = ({ screen, className, href }) => {
  return (
    <div className={`footer ${screen} ${className}`}>
      <div className="frame">
        <div className="partners-chainback">
          {screen === "mobile" && <a href={"mailto:gpucloud@chainback.org"}>gpucloud@chainback.org</a>}

          {screen === "desktop" && <>© 2024</>}
        </div>
        {screen === "desktop" && (
          <>
            <div className="chainback-glyph">
              <img className="group" alt="Group" src="/img/group-48095374-3.png" />
            </div>
            <div className="text-wrapper-4">Chainback Cloud Computing</div>
          </>
        )}

        {screen === "mobile" && <div className="privacy">Privacy Policy</div>}
      </div>
      <div className="frame-2">
        {screen === "desktop" && (
            <a href={"mailto:gpucloud@chainback.org"}>cloud@gpuchainback.org</a>
        )}

        <div className="privacy-2">
          {screen === "desktop" && <a href={"https://chainback.gitbook.io"}>Whitepaper</a>}

          {screen === "mobile" && <>© 2024</>}
        </div>
        {screen === "mobile" && (
          <>
            <div className="chainback-glyph">
              <img className="group" alt="Group" src="/img/group-48095374-3.png" />
            </div>
            <div className="text-wrapper-4">Chainback Cloud Computing</div>
          </>
        )}
      </div>
    </div>
  );
};

Footer.propTypes = {
  screen: PropTypes.oneOf(["desktop", "mobile"]),
  href: PropTypes.string,
};
