import "./style.scss";
import {useContext, useEffect, useState} from "react";
import OsSection from "../../components/OsSection/OsSection";
import {getAvailableServers, getOsInfo} from "../../api/public";
import {ServerCustomizationChoice} from "../../components/ServerCustomizationChoice";
import {
  SERVER_CUSTOMIZATION_ADVANCED,
  SERVER_CUSTOMIZATION_BASIC
} from "../../components/ServerCustomizationChoice/ServerCustomizationChoice";
import {GpuOptionsSection} from "../../components/GpuOptionsSection";
import {PlansSection} from "../../components/PlansSection";
import {ServerConfigurationSection} from "../../components/ServerConfigurationSection";
import UserContext from "../../components/UserContext";
import {getSshKeys} from "../../api/auth";
import {OrderSummarySection} from "../../components/OrderSummarySection";
import {isLoggedInUser} from "../../api/utils";
import {Tooltip} from "react-tooltip";

const MainPage = () => {
  const [servers, setServers] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [serverCustomizationType, setServerCustomizationType] = useState(SERVER_CUSTOMIZATION_BASIC);
  const [gpuTypesByOs, setGpuTypesByOs] = useState({});
  const [selectedOs, setSelectedOs] = useState(null);
  const [selectedGpu, setSelectedGpu] = useState(null);
  const [serversByOs, setServersByOs] = useState({});
  const [serversByGpu, setServersByGpu] = useState({});
  const [suitablePlans, setSuitablePlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [sshKeys, setSshKeys] = useState([]);
  const [selectedSshKeyId, setSelectedSshKeyId] = useState(null);

  const user = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInUser(user));

  const handleServerCustomizationChange = type => {
    setServerCustomizationType(type);
  }

  const processSshKeys = async () => {
    if (isLoggedInUser(user)) {
      getSshKeys().then(keys => {
        setSshKeys(keys);
      });
    }
  }

  useEffect(() => {
    processSshKeys();
    getAvailableServers().then(data => {
      setServers(data);
      const byOs = {};
      const byGpu = {};
      const gpusByOs = {};
      data.forEach(server => {
        if (!server?.regions?.length) {
          return;
        }
        const gpuType = server.gpu_type;
        const osSet = new Set();
        server.os_options.forEach(os => {
          osSet.add(os);
          if (!byOs[os]) {
            byOs[os] = [];
          }
          if (!gpusByOs[os]) {
            gpusByOs[os] = new Set();
          }
          gpusByOs[os].add(gpuType);
          byOs[os].push(server);
        });
        if (!byGpu[gpuType]) {
          byGpu[gpuType] = [];
        }
        server.os_set = osSet;
        byGpu[gpuType].push(server);
      });
      Object.keys(gpusByOs).forEach(os => gpusByOs[os] = Array.from(gpusByOs[os]));
      setGpuTypesByOs(gpusByOs);
      setServersByOs(byOs);
      setServersByGpu(byGpu);

      getOsInfo().then(osInfo => {
        const opts = [];
        osInfo.forEach(os => {
          if (byOs[os.os_id]) {
            opts.push(os);
          }
        });
        setOsOptions(opts);
      })
    });
  }, [user]);

  const prepareSuitablePlans = (gpu) => {
    const servers = serversByGpu[gpu].filter(s => s.os_set.has(selectedOs.os_id)).sort((a, b) => a.price.hourly - b.price.hourly);
    setSuitablePlans(servers);
    if (servers?.length) {
      setSelectedPlan(servers[0])
    } else {
      setSelectedPlan(null);
    }
  }

  const handleOsSelect = os => {
    setSelectedOs(os);
    setSelectedGpu(null);
    setSuitablePlans([]);
  }

  const handleGpuSelect = gpu => {
    setSelectedGpu(gpu);
    prepareSuitablePlans(gpu);
  }

  const handlePlanSelect = plan => {
    setSelectedPlan(plan);
  }

  const handleSshKeysUpdate = () => {
    processSshKeys();
  }

  const handleSshKeySelect = (keyId) => {
    setSelectedSshKeyId(keyId);
  }

  return (
      <>
        <div className="promo-section">
          <div className="promo-container">
            <div className="promo-content">
              <div className="input-label-8">Virtual Machines</div>
              <p className="p">
                    <span className="text-wrapper-6">
                      Launch a variety of GPU and CPU-Only Virtual Servers within minutes. Feel free to reach out to{" "}
                    </span>
                <a href="mailto:support@chainback.org" rel="noopener noreferrer" target="_blank">
                  <span className="text-wrapper-7">support@chainback.org</span>
                </a>
                <span className="text-wrapper-6"> with any questions!</span>
              </p>
            </div>
          </div>
        </div>
        <OsSection onSelectOs={handleOsSelect} options={osOptions} selectedOs={selectedOs}/>
        <div className="server-selection-wrapper">
          {selectedOs && <GpuOptionsSection gpuTypes={gpuTypesByOs[selectedOs.os_id]} onSelect={handleGpuSelect}
                                            selectedGpu={selectedGpu}/>}
          {!selectedGpu && <Tooltip anchorSelect={".no-gpu-selected"}>Please select OS and GPU.</Tooltip>}
          <ServerCustomizationChoice
              className={!selectedGpu ? 'no-gpu-selected' : ''}
              selected={serverCustomizationType}
              onSelect={handleServerCustomizationChange}
              disabled={!selectedGpu}/>
        </div>

        {serverCustomizationType === SERVER_CUSTOMIZATION_ADVANCED && suitablePlans?.length && <PlansSection
            options={suitablePlans}
            onPlanSelect={handlePlanSelect}
            selectedPlanId={selectedPlan.plan_id}
        />}

        <div className="server-selection-wrapper">
          <ServerConfigurationSection sshKeys={sshKeys}
                                      onKeysUpdate={handleSshKeysUpdate}
                                      selectedKeyId={selectedSshKeyId}
                                      onKeySelect={handleSshKeySelect}
                                      plan={selectedPlan}
          />
        </div>
        <div className="server-selection-wrapper">
          <OrderSummarySection os={selectedOs} gpu={selectedGpu} plan={selectedPlan}
                               ssh={sshKeys.find(s => s.keyId === selectedSshKeyId)}/>
        </div>
      </>
  )
}

export default MainPage;
