import "./style.scss";
import {ButtonDefaultPill} from "../ButtonDefaultPill";
import {defaultHandler} from "../../api/utils";

export const GpuOptionsSection = ({gpuTypes = [], onSelect = defaultHandler, selectedGpu = null}) => {
  return (
      <div className="gpu-options">
        <div className="caption">Select GPU</div>
        <div className="gpu-container">
          {gpuTypes.map(gpuType => <ButtonDefaultPill
              key={gpuType}
              className="design-component-instance-node-2"
              text={gpuType.replace(/_/g, ' ')}
              type={gpuType === selectedGpu ? 'active' : 'default'}
              onClick={() => onSelect(gpuType)}
          />)}
        </div>
      </div>
  )
}
