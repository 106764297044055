export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const shortenWalletAddress = (address, symbolsLimit = 12) => {
  return address.slice(0, symbolsLimit - 5 - 3) + '...' + address.slice(address.length - 5);
}

export const defaultHandler = new Function();

export const formatMoneyValue = value => Number(value).toFixed(2);

export const displayDollars = value => '$' + formatMoneyValue(value);

export const isLoggedInUser = user => !!user?.id;
