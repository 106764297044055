import React, {useContext} from "react";
import "./style.scss";
import {FormField} from "../FormField";
import {AmountInput} from "../AmountInput";
import {CustomSelect} from "../CustomSelect";
import {BtnPrimaryMd} from "../BtnPrimaryMd";
import {useEffect, useState} from "react";
import {defaultHandler, displayDollars, isLoggedInUser} from "../../api/utils";
import {createSshKey} from "../../api/auth";
import {Tooltip} from "react-tooltip";
import UserContext from "../UserContext";

export const ServerConfigurationSection = ({
                                             sshKeys = [],
                                             selectedKeyId = null,
                                             onKeysUpdate = defaultHandler,
                                             onKeySelect = defaultHandler,
                                             plan
                                           }) => {
  const [newSshKeyName, setNewSshKeyName] = useState("");
  const [newSshKeyPublicKey, setNewSshKeyPublicKey] = useState("");
  const [buttonState, setButtonState] = useState('disabled');
  const [showForm, setShowForm] = useState(false);
  const user = useContext(UserContext);
  const [isCreatingSsh, setIsCreatingSsh] = useState(false);
  const [keyError, setKeyError] = useState('');

  useEffect(() => {
    setButtonState(newSshKeyName && newSshKeyPublicKey && !isCreatingSsh ? 'default' : 'disabled');
  }, [newSshKeyName, newSshKeyPublicKey, isCreatingSsh, user])

  const handleInput = (name, {target: {value}}) => {
    if (name === 'name') {
      setNewSshKeyName(value);
    } else if (name === 'public_key') {
      setNewSshKeyPublicKey(value);
    }
  }

  const handleCreateSshKeyClick = async () => {
    setIsCreatingSsh(true);
    setKeyError('');
    try {
      const newKey = await createSshKey(newSshKeyName, newSshKeyPublicKey);
      setShowForm(false);
      setNewSshKeyPublicKey("");
      setNewSshKeyName("");
      onKeysUpdate();
      onKeySelect(newKey.keyId);
    } catch (e) {
      setKeyError('Please enter valid ssh key and try again');
    } finally {
      setIsCreatingSsh(false);
    }
  }

  const handleKeyChange = ({value}) => {
    if (value === 'create') {
      setShowForm(true);
      onKeySelect(null);
    } else {
      setShowForm(false);
      onKeySelect(value);
    }
  }

  const sshKeysOptions = sshKeys.map(({name, keyId}) => ({label: name, value: keyId}));
  if (selectedKeyId) {
    sshKeysOptions.find(({value}) => value === selectedKeyId);
  }
  sshKeysOptions.push({label: 'Create new...', value: 'create'})

  const prepareValue = v => v ? displayDollars(v) + ' per hour' : '';

  return (<>
    <div className="div-4">
      <div className="inputlabel">Configure Server</div>
    </div>
    <div className="div-5">
      <div className="configuration-block">
        <div className="configuration-block-5">SSH Key</div>
        <div className="configuration-block-6">
          <CustomSelect
              placeHolder={"Select or create key"}
              options={sshKeysOptions}
              onChange={handleKeyChange}
              selected={selectedKeyId}
          />
          {showForm && <div className="form-container">
            <h4>Please fill these fields:</h4>
            <input className="text-input" type="text" placeholder="Name" onChange={(e) => handleInput('name', e)}
                   value={newSshKeyName}/>
            <textarea className="text-input" value={newSshKeyPublicKey} placeholder="Public key (usually content of file id_rsa.pub)"
                      onChange={(e) => handleInput('public_key', e)}/>
            {keyError && <span style={{color: '#F5576E'}}>{keyError}</span>}
            <div className="button-container">
              {!isLoggedInUser(user) && <Tooltip
                  style={{backgroundColor: '#F5576E', borderRadius: '6px'}}
                  anchorSelect=".not-logged-in">Need to login to access this feature.</Tooltip>}
              <BtnPrimaryMd text={"Create SSH key"} className={'ssh-create-btn' + (isLoggedInUser(user) ? '' : ' not-logged-in')} type={buttonState} onClick={handleCreateSshKeyClick}/>
            </div>
          </div>}
        </div>
      </div>
      <div className="configuration-block">
        <div className="configuration-block-5">Commitment</div>
        <div className="configuration-block-7">
          <AmountInput
              className="design-component-instance-node-2 blocked"
              text="ON DEMAND"
              type="active"
              value={prepareValue(plan?.price?.hourly)}
          />
          <AmountInput
              className="design-component-instance-node-2 disabled coming-soon"
              text="MONTHLY"
              type="default"
              value={prepareValue(plan?.price?.monthly)}
          />
          <AmountInput
              className="design-component-instance-node-2 disabled coming-soon"
              text="6 MONTH"
              type="default"
              value={prepareValue(plan?.price?.six_monthly)}
          />
          <AmountInput
              className="design-component-instance-node-2 disabled coming-soon"
              text="ANNUAL"
              type="default"
              value={prepareValue(plan?.price?.yearly)}
          />
        </div>
      </div>
      <div className="configuration-block disabled coming-soon">
        <div className="configuration-block-2">Additional Disk</div>
        <CustomSelect disabled={true} placeHolder={"Select amount"} options={[]}/>
      </div>
    </div>
  </>)
}
