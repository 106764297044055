import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.scss";

export const LaunchpadTabPanel = ({ type, className, text = "GPU", onClick }) => {
  return (
    <div
      className={`launchpad-tab-panel type-1-${type} ${className}`}
      onClick={onClick}
    >
      <div className="tab">
        <div className="input-label-4">{text}</div>
      </div>
    </div>
  );
};

LaunchpadTabPanel.propTypes = {
  type: PropTypes.oneOf(["hover", "active", "default"]),
  text: PropTypes.string,
};
