import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.scss";

export const AmountInput = ({ type = 'default', className, text = "ANNUAL", value}) => {
  return (
    <div
      className={`amount-input type-8-${type} ${className}`}
    >
      <div className="div-2">
        <div className={`input-label-7 amount-input-instance`}>{text}</div>
        <div className={`input-value-3 amount-input-instance`}>{value}</div>
      </div>
    </div>
  );
};

AmountInput.propTypes = {
  type: PropTypes.oneOf(["active", "default"]),
  text: PropTypes.string,
};
