/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1Variant2 = ({ className }) => {
  return (
    <svg
      className={`property-1-variant2 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_222_2136)">
        <mask
          className="mask"
          height="34"
          id="mask0_222_2136"
          maskUnits="userSpaceOnUse"
          style="mask-type:luminance"
          width="34"
          x="3"
          y="3"
        >
          <path
            className="path"
            d="M20.0002 36.6666C29.2052 36.6666 36.6668 29.2049 36.6668 19.9999C36.6668 10.7949 29.2052 3.33325 20.0002 3.33325C10.7952 3.33325 3.3335 10.7949 3.3335 19.9999C3.3335 29.2049 10.7952 36.6666 20.0002 36.6666Z"
            fill="#555555"
          />
          <path
            className="path"
            d="M24.7145 15.2856L15.2861 24.714M15.2861 15.2856L24.7145 24.714"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3.33333"
          />
        </mask>
        <g className="g" mask="url(#mask0_222_2136)">
          <path className="path" d="M0 0H40V40H0V0Z" fill="url(#paint0_linear_222_2136)" />
        </g>
      </g>
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_222_2136"
          x1="1.74545"
          x2="43.3455"
          y1="40"
          y2="40"
        >
          <stop className="stop" stopColor="#46FF7A" />
          <stop className="stop" offset="0.484375" stopColor="#52CBE7" />
          <stop className="stop" offset="1" stopColor="#7650DC" />
        </linearGradient>
        <clipPath className="clip-path" id="clip0_222_2136">
          <rect className="rect" fill="white" height="40" width="40" />
        </clipPath>
      </defs>
    </svg>
  );
};
