import PropTypes from "prop-types";
import React from "react";
import { AChainbackLogo } from "../../icons/AChainbackLogo";
import { AChainbackLogo1 } from "../../icons/AChainbackLogo1";
import { ResponsiveMenu } from "../ResponsiveMenu";
import "./style.css";
import {UserHeaderPanel} from "../UserHeaderPanel";
import {ProductsMenu} from "../ProductsMenu";

export const Header = ({ type, className, isMobileMenu = true, onLoginClick = () => {}, onLogoutClick = () => {} } ) => {
  return (
    <div className={`header property-1-${type} ${className}`}>
      <div className="div">
        {type === "default" && (
          <>
            <p className="input-label">
              <span className="text-wrapper">
                {/*Chainback Console*/}
                <br />
                Our GPU Cloud is currently undergoing maintenance and as a result you may experience difficulties
                interacting with the console.
              </span>
            </p>
            <ProductsMenu/>
            <UserHeaderPanel onLoginClick={onLoginClick} onLogoutClick={onLogoutClick}/>
            <>{isMobileMenu && <ResponsiveMenu property1="default" />}</>
          </>
        )}

        {["variant-2", "variant-3"].includes(type) && (
          <div className="header-responsive">
            {type === "variant-2" && (
              <>
                <AChainbackLogo1 className="a-chainback-logo" />
                <div className="column-2">
                  <p className="span-wrapper">
                    <span className="span">
                      Chainback Console
                      <br />
                      Our backend is currently undergoing maintenance and as a result you may experience difficulties
                      interacting with the console.
                    </span>
                  </p>
                  <ResponsiveMenu property1="default" />
                </div>
              </>
            )}

            {type === "variant-3" && (
              <>
                <AChainbackLogo className="a-chainback-logo" />
                <div className="responsive-menu-wrapper">
                  <ResponsiveMenu
                    img="/img/line-2-1.svg"
                    line="/img/line-2-1.svg"
                    line1="/img/line-3-1.svg"
                    property1="default"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  type: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  btnPrimaryMdText: PropTypes.string,
  isMobileMenu: PropTypes.bool,
};
