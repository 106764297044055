/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";
import {defaultHandler} from "../../api/utils";

export const BtnPrimaryMd = ({ type, className, text = "Button text", onClick = defaultHandler }) => {
  return (
    <div
      className={`btn-primary-md ${type} ${className}`}
      onClick={type === 'disabled' ? defaultHandler : onClick}
    >
      <div className="explorer">{text}</div>
    </div>
  );
};

BtnPrimaryMd.propTypes = {
  type: PropTypes.oneOf(["primary", "default", "disabled"]),
  text: PropTypes.string,
  onClick: PropTypes.func
};
