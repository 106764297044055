import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.scss";
import {defaultHandler} from "../../api/utils";

export const ButtonDefaultPill = ({ type, className, text = "TESLA V100 SXM2 16GB", onClick = defaultHandler }) => {
  return (
    <div
      className={`button-default-pill type-3-${type} ${className}`}
      onClick={onClick}
    >
      <div className="button-pill">
        <div className="input-label-5">{text}</div>
      </div>
    </div>
  );
};

ButtonDefaultPill.propTypes = {
  type: PropTypes.oneOf(["disabled", "active", "default"]),
  text: PropTypes.string,
};
