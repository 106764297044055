import PropTypes from "prop-types";
import React from "react";
import "./style.scss";
import iconHome from "./../../img/icon-home.svg";
import iconArrowCircleLeft from "./../../img/icon-arrow-circle-left.svg";
import iconBill from "./../../img/icon-bill.svg";
import iconCalendar from "./../../img/icon-calendar.svg";
import iconConnectionPoint from "./../../img/icon-connection-point.svg";
import iconDataDisplay from "./../../img/icon-data-display.svg";
import iconHandbag from "./../../img/icon-handbag.svg";
import iconHelp from "./../../img/icon-help.svg";
import iconServer from "./../../img/icon-server.svg";
import iconVerticalTimeline from "./../../img/icon-vertical-timeline.svg";
import {NavLink} from "react-router-dom";

export const Sidebar = ({ className }) => {
  return (
    <div className={`sidebar-sidebar ${className}`}>
      <NavLink className={'menu-item'} to="/">
        <img className="menu-item-icon" alt="Menu item icon for Available GPUs" src={iconDataDisplay} />
        <span className="menu-item-text">Available GPUs</span>
      </NavLink>
      <NavLink className={'menu-item'} to="/servers">
        <img className="menu-item-icon" alt="Menu item icon for Your servers" src={iconServer} />
        <span className="menu-item-text">Your Servers</span>
      </NavLink>
      <div className="menu-item disabled coming-soon">
        <img className="menu-item-icon" alt="Menu item icon for Available clusters" src={iconHandbag} />
        <span className="menu-item-text">Available Clusters</span>
      </div>
      <div className="menu-item disabled coming-soon">
        <img className="menu-item-icon" alt="Menu item icon for Custom requests" src={iconVerticalTimeline} />
        <span className="menu-item-text">Custom Requests</span>
      </div>
      <div className="menu-item disabled coming-soon">
        <img className="menu-item-icon" alt="Menu item icon for Billing" src={iconBill} />
        <span className="menu-item-text">Billing</span>
      </div>
      <div className="menu-item disabled coming-soon">
        <img className="menu-item-icon" alt="Menu item icon for Event Scheduler" src={iconCalendar} />
        <span className="menu-item-text">Event Scheduler</span>
      </div>
      {/*<Link to="https://app.chainback.org">*/}
      {/*  <div className="menu-item">*/}
      {/*    <img className="menu-item-icon" alt="Menu item icon for main page of chainback projects" src={iconArrowCircleLeft} />*/}
      {/*    <span className="menu-item-text">Back to Chainback</span>*/}
      {/*  </div>*/}
      {/*</Link>*/}
    </div>
  );
};

Sidebar.propTypes = {
  menuLinkText: PropTypes.string,
};
