/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AChainbackLogo1 = ({ className }) => {
  return (
    <svg
      className={`a-chainback-logo-1 ${className}`}
      fill="none"
      height="128"
      viewBox="0 0 230 128"
      width="230"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse className="ellipse" cx="28.9289" cy="64" fill="url(#paint0_linear_222_3349)" rx="18.9285" ry="18.9128" />
      <path
        className="path"
        d="M32.6711 62.7642L28.8053 59.8511L24.9395 62.0205V66.3592L28.8053 68.5286L32.6711 65.6154H39.5297V62.7642H32.6711Z"
        fill="white"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M16.5991 56.6614C23.7975 52.4898 32.6805 52.4898 39.8789 56.6614L38.4845 59.0634C32.3774 55.5242 24.8925 55.3963 18.6858 58.6798V69.728C24.8925 73.0115 32.3774 72.8837 38.4845 69.3445L39.8789 71.7465C32.6805 75.9181 23.7975 75.9181 16.5991 71.7465C16.1705 71.4981 15.9067 71.0405 15.9067 70.5455V57.8624C15.9067 57.3674 16.1705 56.9098 16.5991 56.6614Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M69.1631 59.8174C69.8926 59.8174 70.6221 59.9215 71.3516 60.1645C72.0812 60.4074 72.8107 60.7892 73.5402 61.3446L74.9297 58.1166C74.2697 57.5265 73.436 57.0753 72.3591 56.7282C71.3169 56.3811 70.2053 56.2075 69.0589 56.2075C67.2525 56.2075 65.6545 56.5893 64.3344 57.3182C63.0143 58.0472 61.9722 59.0885 61.2427 60.4074C60.5479 61.7611 60.1658 63.2884 60.1658 65.0586C60.1658 66.8288 60.5132 68.3907 61.2427 69.7444C61.9722 71.0634 63.0143 72.1047 64.3344 72.8683C65.6545 73.5973 67.2525 73.9791 69.0589 73.9791C70.2053 73.9791 71.2822 73.8055 72.3591 73.4584C73.436 73.1113 74.3044 72.6601 74.9297 72.07L73.5402 68.842C72.8107 69.3626 72.0812 69.7791 71.3516 70.0221C70.6221 70.2651 69.8926 70.4039 69.1631 70.4039C67.7736 70.4039 66.7314 69.9527 65.9671 69.0502C65.2376 68.1478 64.8555 66.8288 64.8555 65.0933C64.8555 63.3578 65.2376 62.0388 65.9671 61.1711C66.6967 60.2686 67.7736 59.8174 69.1631 59.8174Z"
        fill="white"
      />
      <path
        className="path"
        d="M89.9728 63.1507H82.9903V56.4517H78.509V73.7373H82.9903V66.7258H89.9728V73.7373H94.4541V56.4517H89.9728V63.1507Z"
        fill="white"
      />
      <path
        className="path"
        d="M104.769 56.4517L96.675 73.7373H101.261L102.789 70.1621H110.466L111.995 73.7373H116.476L108.382 56.4517H104.769ZM104.283 66.7258L106.645 61.2416L109.007 66.7258H104.283Z"
        fill="white"
      />
      <path className="path" d="M123.286 56.4517H118.7V73.7373H123.286V56.4517Z" fill="white" />
      <path
        className="path"
        d="M138.781 66.2052L130.93 56.4517H127.595V73.7373H131.868V63.9143L139.719 73.7373H143.089V56.4517H138.781V66.2052Z"
        fill="white"
      />
      <path
        className="path"
        d="M159.067 64.745C159.866 64.4673 160.526 64.016 160.978 63.3913C161.499 62.6624 161.777 61.864 161.777 60.9269C161.777 59.5385 161.221 58.4624 160.144 57.6294C159.067 56.8311 157.643 56.4146 155.837 56.4146H147.43V73.7349H156.045C157.956 73.7349 159.45 73.3183 160.561 72.4506C161.673 71.5828 162.229 70.4374 162.229 69.0143C162.229 67.9036 161.916 66.9664 161.291 66.2375C160.804 65.5433 160.04 65.0574 159.067 64.745ZM151.876 59.8856H155.072C155.906 59.8856 156.497 60.0244 156.879 60.3021C157.296 60.545 157.504 60.9963 157.504 61.5863C157.504 62.1417 157.296 62.5582 156.914 62.8359C156.531 63.1136 155.906 63.2177 155.072 63.2177H151.876V59.8856ZM157.4 69.882C157.018 70.1597 156.393 70.2986 155.524 70.2986H151.876V66.654H155.524C156.427 66.654 157.053 66.7929 157.435 67.1052C157.817 67.3829 157.991 67.8342 157.991 68.4936C157.991 69.1531 157.782 69.6044 157.4 69.882Z"
        fill="white"
      />
      <path
        className="path"
        d="M171.746 56.4517L163.651 73.7373H168.237L169.765 70.1621H177.443L178.971 73.7373H183.452L175.358 56.4517H171.746ZM171.259 66.7258L173.621 61.2416L175.984 66.7258H171.259Z"
        fill="white"
      />
      <path
        className="path"
        d="M193.7 59.8174C194.429 59.8174 195.159 59.9215 195.888 60.1645C196.618 60.4074 197.347 60.7892 198.077 61.3446L199.466 58.1166C198.806 57.5265 197.973 57.0753 196.896 56.7282C195.853 56.3811 194.742 56.2075 193.595 56.2075C191.789 56.2075 190.191 56.5893 188.871 57.3182C187.551 58.0472 186.509 59.0885 185.779 60.4074C185.084 61.7611 184.737 63.2884 184.737 65.0586C184.737 66.8288 185.084 68.3907 185.814 69.7444C186.543 71.0634 187.586 72.1047 188.906 72.8683C190.226 73.5973 191.824 73.9791 193.63 73.9791C194.777 73.9791 195.853 73.8055 196.93 73.4584C198.007 73.1113 198.876 72.6601 199.501 72.07L198.111 68.842C197.382 69.3626 196.652 69.7791 195.923 70.0221C195.193 70.2651 194.464 70.4039 193.734 70.4039C192.345 70.4039 191.303 69.9527 190.538 69.0502C189.809 68.1478 189.427 66.8288 189.427 65.0933C189.427 63.3578 189.809 62.0388 190.538 61.1711C191.268 60.2686 192.345 59.8174 193.7 59.8174Z"
        fill="white"
      />
      <path
        className="path"
        d="M212.08 64.6432L219.722 56.4517H214.372L207.633 63.8796V56.4517H203.082V73.7373H207.633V65.8928L214.581 73.7373H220L212.08 64.6432Z"
        fill="white"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_222_3349"
          x1="11.6524"
          x2="51.0236"
          y1="82.9128"
          y2="82.9128"
        >
          <stop className="stop" stopColor="#46FF7A" />
          <stop className="stop" offset="0.484375" stopColor="#52CBE7" />
          <stop className="stop" offset="1" stopColor="#7650DC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
