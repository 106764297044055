import PropTypes from "prop-types";
import React from "react";
import {RadioButton} from "../RadioButton";
import "./style.scss";
import infoIcon from "../../img/icon-info.svg";
import {Tooltip} from "react-tooltip";
import {defaultHandler} from "../../api/utils";

export const OsCard = ({
    id,
   type,
   className,
   title = "Ubuntu",
   subtitle = "Plain",
   version = "20.04",
   description = "",
   onClick = defaultHandler
}) => {
  return (
      <div
          className={`os-card type-${type} ${className}`}
          onClick={onClick}
      >
        <div className="option-wrapper">
          <div className="option-container">
            <div className="radio-block">
              <RadioButton
                  className={`${type === 'active' && "class"}`}
                  type={type}
              />
              {description && <>
                <Tooltip style={{maxWidth: '200px', fontSize: '12x'}} anchorId={"info-icon-" + id}>{description}</Tooltip>
                <img
                    id={"info-icon-" + id}
                    className="icon-park-twotone"
                    alt="Icon park twotone"
                    src={infoIcon}
                />
              </>}
            </div>
            <div className="input-label-2">{title}</div>
            <div className="input-label-2">{version}</div>

            <div className="input-label-3">{subtitle}</div>
          </div>
        </div>
      </div>
  );
};

OsCard.propTypes = {
  type: PropTypes.oneOf(["hover", "active", "default"]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  version: PropTypes.string,
  onClick: PropTypes.func
};
